import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram
} from "planby";

export const ProgramItem = ({ program, onClick, searchQuery, searchMode, isSearching, isLoading, ...rest }) => {
  const {
    styles,
    formatTime,
    set12HoursTimeFormat,
    isLive,
    isMinWidth
  } = useProgram({
    program,
    ...rest
  });

  const { data } = program;
  const { image, title, since, till, credits } = data;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  const highlightBackground = searchQuery.trim().length > 0 && !isLoading && (searchMode === 'programmes' || searchMode === 'credits') && !isSearching && (searchMode === 'programmes' ? title.toLowerCase().includes(searchQuery.toLowerCase()) : searchMode === 'credits' ? credits.toLowerCase().includes(searchQuery.toLowerCase()) : '') ? '#2c6699' : '#1a202c';
  return (
    <ProgramBox onClick={onClick} width={styles.width} style={{ ...styles.position }}>
      <ProgramContent style={{background: highlightBackground}} className="programme-container" width={styles.width} isLive={isLive}>
        <ProgramFlex >
          {isLive && isMinWidth && image && <ProgramImage src={image} alt="Preview" />}
          <ProgramStack>
            <ProgramTitle>{title}</ProgramTitle>
            <ProgramText>
              {sinceTime} - {tillTime}
            </ProgramText>
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};
