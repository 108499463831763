import axios from "axios";
let cancelTokenSource = null;
export const fetchChannelsAndProgrammes = async (params) => {
  try {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Cancelled due to new request.');
    }
    cancelTokenSource = axios.CancelToken.source();
    const response = await axios.get('/channels-with-programmes', {
      params,
      cancelToken: cancelTokenSource.token,
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log('Request canceled:', error.message);
    } else {
      console.error('Failed to fetch channels:', error);
      throw error;
    }
  }
};

export const fetchMinMaxProgrammeDate = async (params) => {
  try {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Cancelled due to new request.');
    }
    cancelTokenSource = axios.CancelToken.source();

    const response = await axios.get( '/min-max-programme-date', {
      params,
      cancelToken: cancelTokenSource.token,
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log('Request canceled:', error.message);
    } else {
      console.error('Failed to fetch channels:', error);
      throw error;
    }
  }
};


export const submitChannelBodhiId = async (channelId, bodhiId) => {
  try {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Cancelled due to new request.');
    }
    cancelTokenSource = axios.CancelToken.source();
    const body = {
      "channelId": channelId,
      "bodhiId": bodhiId
    }
    const response = await axios.post( '/channel-bodhi-update', body);
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log('Request canceled:', error.message);
    } else {
      console.error('Failed to fetch channels:', error);
      throw error;
    }
  }
};