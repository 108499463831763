import React from "react";

import { useEpg } from "planby";

import { theme } from "./helpers/theme";

import {fetchChannelsAndProgrammes} from "./helpers";

export function useApp() {
  const [dateValue, setDateValue] = React.useState(() => {
    const today = new Date();
    return new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())).toISOString().slice(0, 10);
  });  
  const [channels, setChannels] = React.useState([]);
  const [epg, setEpg] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);


  const channelsData = React.useMemo(() => channels, [channels]);
  const epgData = React.useMemo(() => epg, [epg]);

  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: 21600,
    sidebarWidth: 300,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: false,
    startDate: dateValue + "T00:00:00",
    endDate: dateValue + "T24:00:00",
    isBaseTimeFormat: true,
    theme
  });

  const handleFetchResources = React.useCallback(async (params = {}) => {
    setIsLoading(true);
    const channelsAndProgrammes = await fetchChannelsAndProgrammes(params) || [];
    let channelsData = channelsAndProgrammes.length > 0 ? channelsAndProgrammes.reduce((acc, item) => {
      // Check if the channel_id has already been added to the Map
      if (!acc.has(item.channel_id)) {
        // If not present, add the item with channel_id as the key
        acc.set(item.channel_id, {
          uuid: item.channel_id,
          type: "channel",
          title: item.channel_id,
          provider: item.channel_source_id,
          logo: item.channel_icon,
          bodhi_outlet_id: item.bodhi_outlet_id,
        });
      }
      return acc;
    }, new Map()) : [
      {
        uuid: 0,
        type: "channel",
        title: 'No Channels or Programmes Found',
        provider: null,
        logo: null,
        bodhi_outlet_id: null,
        render_bodhi_id: false
      }
    ];
    channelsData = Array.from(channelsData.values());
    let epg = channelsAndProgrammes
    .filter(i => i.programme_start != null && i.programme_stop != null)
    .map(i => {
      return {
        "channelUuid": i.programme_channel_name,
        "id": i.programme_id,
        "image": i.programme_icon,
        "since": formatDateTime(i.programme_start),
        "till": formatDateTime(i.programme_stop),
        "title": i.programme_title,
        "description": i.programme_description,
        "details": i.programme_details,
        "category": i.programme_categories,
        "credits": i.programme_credits
      }
    });  
    const channels = channelsData;
    setEpg(epg);
    setChannels(channels);
    setIsLoading(false);
  }, []);

  const reloadEpgData = React.useCallback((params) => {
    handleFetchResources(params);
  }, [handleFetchResources]);


  const updateChannelBodhiId = (channelId, newBodhiId) => {
    const index = channels.findIndex(channel => channel.uuid === channelId);
    if (index !== -1) {
      const updatedChannels = [...channels];
      updatedChannels[index] = { ...updatedChannels[index], bodhi_outlet_id: newBodhiId };
      setChannels(updatedChannels);
    }
  };


  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) {
      console.error('Invalid date string:', dateTimeStr);
      return '';
    }
    let year = dateTimeStr.substring(0, 4);
    let month = dateTimeStr.substring(4, 6);
    let day = dateTimeStr.substring(6, 8);
    let hour = dateTimeStr.substring(8, 10);
    let minute = dateTimeStr.substring(10, 12);
    let second = dateTimeStr.substring(12, 14);
    let formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    return formattedDateTime;
  }

  return { getEpgProps, getLayoutProps, isLoading, reloadEpgData, dateValue, setDateValue, setIsLoading, formatDateTime, updateChannelBodhiId};
}
