import React from 'react';
import '../styles/loader.css';

export const Loader = ({ wrapperStyle, className, showOverlay = false }) =>  {
  return (
    <div className={`loader-wrapper ${className}`} style={wrapperStyle}>
      {showOverlay && <div className='overlay'></div>}
      <div className="loader">
        <div className="inner"></div>
        <div className="outer"></div>
      </div>
    </div>
  );
}

