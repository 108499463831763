import React, { useState, useCallback, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { Epg, Layout } from "planby";
import { useApp } from "../useApp";
import { Timeline, ChannelItem, ProgramItem, Modal, Loader, Icon } from "../components";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../styles/index.css";
import _ from 'lodash';
import {fetchMinMaxProgrammeDate, submitChannelBodhiId} from "../helpers";


function Home() {
  const ALL_MODE_OPTIONS = ['channels', 'programmes', 'credits'];
  const ALL_EPG_OPTIONS = [{title:"Prod EPGs", value:'prod'}, {title:"All EPGs", value:'all'}];
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitLoading, setIsSubmitloading] = useState(false);
  const [isEditingBodhiId, setIsEditingBodhiId] = useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [bodyContent, setBodyContent] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [headerContent, setHeaderContent] = useState(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchMode, setSearchMode] = React.useState(ALL_MODE_OPTIONS[0]);
  const [epgType, setEpgType] = React.useState(ALL_EPG_OPTIONS[0]);
  const [minDate, setMinDate] = React.useState('');
  const [maxDate, setMaxDate] = React.useState('');
  const [currentBodhiId, setCurrentBodhiId] = useState(0);
  const modeDropdownOptions = React.useMemo(() => ALL_MODE_OPTIONS.filter(option => option !== searchMode), [searchMode]);
  const epgDropdownOptions = React.useMemo(() => ALL_EPG_OPTIONS.filter(option => option.title !== epgType.title), [epgType]);
  let [searchParams, setSearchParams] = useSearchParams();
  const epgDropdownRef = useRef();
  const modeDropdownRef = useRef();
  const { 
    isLoading, 
    getEpgProps, 
    getLayoutProps, 
    reloadEpgData,
    dateValue,
    setDateValue,
    setIsLoading,
    formatDateTime,
    updateChannelBodhiId,
  } = useApp();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const selectedDate = new Date(dateValue);
  selectedDate.setHours(0, 0, 0, 0);
  const isNotToday = selectedDate.getTime() !== today.getTime();


  const debouncedSearch = useCallback(_.debounce((searchQueryValue, searchModeValue, date, epgType) => {
    reloadEpgData({startDate: date, searchMode: searchModeValue, searchQuery: searchQueryValue, epg: epgType});
    setIsSearching(false)
  }, 500), []); 


  const adjustDay = (dateStr, adjustment = 0) => {
    const dateObj = new Date(dateStr);
    if (!dateObj.getTime()) {
      console.error("Invalid date provided:", dateStr);
      return; 
    }
    dateObj.setDate(dateObj.getDate() + adjustment);
    return dateObj;
  }

  const normalizeDate = (date) => {
    if(!date) return;
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }

  const openModal = () => setModalOpen(true);

  const closeModal = () => {
    setCurrentBodhiId('')
    setCurrentChannel('')
    setIsEditingBodhiId(false);
    setModalOpen(false);
  }

  const handleDateChange = (date, isTodayClick = false) => {
    if (!date) return;
    setIsLoading(true)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
      setSearchParams(prevParams => {
        const params = new URLSearchParams(prevParams);
        if(!isTodayClick) params.set('date', formattedDate);
        else params.delete('date')
        return params;
      });
    setDateValue(formattedDate)
  };

  const handleProgramSelect = (programData) => {
    openModal();
  };

  const handleSubmitBodhiId = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitloading(true)
      setIsEditingBodhiId(false);
      await submitChannelBodhiId(currentChannel.uuid, currentBodhiId )
      updateChannelBodhiId(currentChannel.uuid, currentBodhiId)
      setIsSubmitloading(false)
    } catch (error) {
      console.error('Error updating Bodhi ID:', error);
    }
  };
  
  const handleChannelSelect = (channelData) => {
    setCurrentChannel(channelData);
    setCurrentBodhiId(channelData.bodhi_outlet_id || '');
    setIsEditingBodhiId(!channelData.bodhi_outlet_id);
    openModal();
  };

  const handleSearchChange = (event) => {
    setIsSearching(true);
    setSearchParams(prevParams => {
        const params = new URLSearchParams(prevParams);
        const searchValue = event.target.value;
        const hasMode = params.has('mode');
        if (searchValue) {
            params.set('search', searchValue);
            params.set('date', dateValue)
            if (!hasMode) {
                params.set('epg', epgType.value)
                params.set('mode', searchMode);
            }
        } else {
            params.delete('search');
            params.delete('mode');
            params.delete('date');
            params.delete('epg')
        }
        return params;
    });
    setSearchQuery(event.target.value);
    debouncedSearch(event.target.value, searchMode, dateValue, epgType);
};

  const handleModeChange = (event) => {
    if (modeDropdownRef && modeDropdownRef.current) {
      modeDropdownRef.current.classList.remove('show'); // Remove 'show' class
    }
    setIsLoading(true);
    const newMode = event.target.value;
    setSearchMode(newMode);
    if (searchQuery) {
      setSearchParams(prevParams => {
        const params = new URLSearchParams(prevParams);
        params.set('mode', newMode);
        return params;
      });
        debouncedSearch(searchQuery, newMode, dateValue, epgType);
    } else {
        setIsLoading(false);
    }
  };

  const handleEpgChange = (event) => {
    if (epgDropdownRef && epgDropdownRef.current) {
      epgDropdownRef.current.classList.remove('show'); // Remove 'show' class
    }
    setIsLoading(true)
    const epgValue = event.target.value;
    const epgObject = ALL_EPG_OPTIONS.find(epg => epg.title === epgValue);

    if (epgObject) {
        setEpgType(epgObject);
        setSearchParams(prevParams => {
            const params = new URLSearchParams(prevParams);
            params.set('epg', epgObject.value);
            return params;
        });
        debouncedSearch(searchQuery, searchMode, dateValue, epgType);
    } else {
        console.error('Selected EPG option not found');
    }
  };

  const handleMinDate = async () => {
    try {
        const apiDate = await fetchMinMaxProgrammeDate();
        if (apiDate && apiDate.length === 1) {
          const formattedDateMin = new Date(formatDateTime(apiDate[0].minDate));
          const formattedDateMax = new Date(formatDateTime(apiDate[0].maxDate));
          return {formattedDateMin,formattedDateMax};
        }
    } catch (error) {
        console.error('Failed to fetch minimum programme date:', error);
        return null;
    }
  };
  
  React.useEffect(() => {
    if (!minDate){
      handleMinDate().then(resolvedMinDate => {
        if (resolvedMinDate) {
          setMinDate(resolvedMinDate.formattedDateMin);
          setMaxDate(resolvedMinDate.formattedDateMax);
        }
      }).catch((e) => {
        console.error('Error setting min date:', e);
      });
    }
  }, []);

  React.useEffect(() => {
    let modalHeaderContent = currentChannel ? <div><h3><strong><u>{currentChannel.title}</u></strong></h3></div> : null;
    let modalBodyContent;
    if (((!currentBodhiId || isEditingBodhiId) && isModalOpen)) {
      modalBodyContent = (
        <>
          <form className="d-flex justify-content-between align-items-center my-3 p-5" onSubmit={handleSubmitBodhiId}>
            <div className="d-flex flex-column justify-content-center ">
              <input
                style={{width:'200px'}}
                type="number"
                className="form-control"
                value={currentBodhiId}
                onChange={(e) => setCurrentBodhiId(e.target.value)}
                required={false}
                placeholder="Enter Bodhi Id"
              />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </>
      );
    } else {
      modalBodyContent = (
        <div className="flex-row d-flex align-items-center justify-content-between my-3 p-5 ">
          <h5>Bodhi ID: {currentBodhiId}</h5>
          <button className="btn btn-secondary" onClick={() => setIsEditingBodhiId(true)}>Edit</button>
        </div>
      );
    }
    setHeaderContent(modalHeaderContent);
    setBodyContent(modalBodyContent);
  }, [isEditingBodhiId, currentBodhiId, currentChannel]);

  React.useEffect(() => {
    setIsLoading(true);
    const today = new Date();
    const todaysDate =  new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())).toISOString().slice(0, 10);

    const searchValue = searchParams.get('search');
    const modeValue = searchParams.get('mode');
    const date = searchParams.get('date');
    const epg = searchParams.get('epg');
    const epgObject = ALL_EPG_OPTIONS.find(option => option.value === epg);
    if (epgObject) {
      setEpgType(epgObject)
    }
    if (searchValue) setSearchQuery(searchValue)

    if (date) setDateValue(date)

    if (modeValue && !searchValue && isInitialized) {
      if (modeValue) setSearchMode(modeValue)
      return;
    }else if( searchValue && modeValue){
      setSearchMode(modeValue);
    }
    debouncedSearch(searchValue, modeValue || searchMode, (searchValue || date) ?  date : dateValue, epgObject?.value || epgType.value);
    setIsInitialized(true)
  },[dateValue, setIsLoading, debouncedSearch, searchParams])

  return (
    <div style={{
      position: 'relative',
      height: '100vh',
      backgroundImage: 'url("/assets/channels.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }}>
      <div className="bg-overlay"></div>
      <div style={{ height: "90%", width: "85%", margin:"0 auto", padding: '2rem 0', position:'relative' }}>
        {(normalizeDate(minDate) !== normalizeDate(adjustDay(dateValue))) &&
          <button  onClick={() => {handleDateChange(adjustDay(dateValue, -1))}} className="previousDayClick">
            <Icon title={'Previous Day'} iconClass={'fa-arrow-left fa-2x'}/>
          </button>
        }
        {(normalizeDate(maxDate) !== normalizeDate(adjustDay(dateValue))) &&
          <button onClick={() => {handleDateChange(adjustDay(dateValue, 1))}} className="nextDayClick">
            <Icon title={'Next Day'} iconClass={'fa-arrow-right fa-2x'}/>
          </button>
        }
        <Modal
          isLoading={isSubmitLoading}
          isOpen={isModalOpen}
          onClose={closeModal}
          headerContent={headerContent}
          bodyContent={bodyContent}
        />
        <div className="d-flex align-items-center">
          <div className="input-group" style={{minWidth:'550px', width:'550px'}}>
            <input
              type="text"
              value={searchParams.get('search')}
              onChange={handleSearchChange}
              placeholder={searchMode === 'channels' ? "Search channels or Bodhi ID's" : searchMode === 'programmes' ? 'Search programmes' : searchMode === 'credits' ? 'Search credits' : '' }
              className="form-control"
            />
            <button
              disabled={isLoading}
              className="btn btn-secondary dropdown-toggle btn-btm-radius"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {searchMode}
            </button>
            <ul className="dropdown-menu dropdown-menu-end" ref={modeDropdownRef}>
              {modeDropdownOptions.map(option => (
                <li key={option}>
                  <button className="dropdown-item" value={option} onClick={handleModeChange}>
                    {option[0].toUpperCase() + option.slice(1)}
                  </button>
                </li>
              ))}
            </ul>
            <button
            className="btn btn-secondary dropdown-toggle btn-btm-radius ms-2"
            type="button"
            disabled={isLoading}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >
              {epgType.title}
            </button>
            <ul className="dropdown-menu dropdown-menu-end" ref={epgDropdownRef}>
            {epgDropdownOptions.map(option => (
                <li key={option}>
                  <button className="dropdown-item" value={option.title} onClick={handleEpgChange}>
                    {option.title[0].toUpperCase() + option.title.slice(1)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <DatePicker
            disabled={isLoading}
            wrapperClassName={isNotToday ? 'datePickerInput' : 'datePickerPos'}
            selected={dateValue}
            onChange={(date) => handleDateChange(date)}
            className="form-control w-100 ms-2"
            minDate={minDate}
            maxDate={maxDate}
            dateFormat="dd/MM/yyyy" 
          />
          {isNotToday && <button className="btn btn-secondary btn-btm-radius" disabled={isLoading} onClick={() => handleDateChange(today, true)}>Today</button>}
        </div>
        <Epg loader={<Loader showOverlay={true} className='center-absolute'/>}  isLoading={isLoading} {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            renderTimeline={(props) => <Timeline {...props} />}
            renderProgram={({ program, ...rest }) => {
              const handleItemClick = () => {
                handleProgramSelect(program);
                const programData = program.data;
                let modalHeaderContent = <div><h3><strong><u>{programData.title}</u></strong></h3></div>
                let modalBodyContent =  <div className="p-4">
                                            <h4><strong>Start / End time:</strong></h4>
                                            <p>{programData.since} - {programData.till}</p>
                                            <h4 className="mt-3"><strong>Description:</strong></h4>
                                            <p>{programData.description}</p>
                                            <h4 className="mt-3"><strong>credits:</strong></h4>
                                            <p>{programData.credits}</p>
                                            <h4 className="mt-3"><strong>Details:</strong></h4>
                                            <p>{programData.details}</p>
                                          </div>;
                setHeaderContent(modalHeaderContent);
                setBodyContent(modalBodyContent);
              };
              return <ProgramItem key={program.data.id} program={program} searchQuery={searchQuery} isSearching={isSearching} isLoading={isLoading} searchMode={searchMode} {...rest} onClick={handleItemClick} />
            }}
            renderChannel={({ channel }) => {
              const handleItemClick = () => handleChannelSelect(channel);
              return <ChannelItem key={channel.uuid} channel={channel} onClick={handleItemClick}  />
            }}
          />
        </Epg>
        </div>
      </div>
  );
}

export default Home;
