import React from 'react';
import { Loader } from "./Loader";

export const Modal = ({ isOpen, onClose, headerContent, bodyContent, isLoading, maxHeight }) => {
  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div onClick={handleOverlayClick} style={styles.overlay}>
      <div onClick={handleModalClick} style={styles.modal}>
        <div style={styles.header}>
          {headerContent}
          <button onClick={onClose} style={styles.closeButton}>×</button>
        </div>
        <div style={styles.body}>
          {isLoading ? <Loader className="center-absolute" /> : bodyContent}
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    zIndex: 999,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    backgroundColor: '#1a202c',
    padding: 20,
    borderRadius: 5,
    width: 500,
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
    padding: 8,
    color: '#fff'
  },
  closeButton: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#fff',
  },
  body: {
    color: '#fff',
    paddingBottom: 20,
    fontSize: 16,
    height: 300,
    overflowY: 'scroll',
    borderTop: '.25px solid white'
  }
};
