import React from "react";
import { ChannelBox, ChannelLogo } from "planby";
import { Icon } from "./Icon";

export const ChannelItem = ({ channel, onClick }) => {
  const { position, logo, title, bodhi_outlet_id, render_bodhi_id } = channel;
  const [hover, setHover] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);

  const style = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    padding: '.5rem 1rem .5rem 2rem',
    backgroundColor: hover ? '#bcbcbc' : 'transparent',
    transition: 'background-color 0.3s'
  };

  return (
    <ChannelBox {...position}>
      <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onClick}
          style={style}
      >
          <div style={{maxWidth: '50px', width:'50px'}}>
        {
          logo && !imageError ? 
          <ChannelLogo
            src={logo}
            alt="Logo"
            style={{ maxHeight: 50, maxWidth: 50, height:50, width:50 }}
            onError={() => setImageError(true)}
          />
          :
          <Icon iconClass={'fa-ban fa-3x'}/>
        }
        </div>
        <div style={{marginLeft:'1rem' , display:'flex', flexDirection:'column',  justifyContent:'center'}}>
          <p style={{fontSize:'12px', color:"#fff"}}>{title}</p>
          {!render_bodhi_id && <p style={{fontSize:'12px', color:"#fff"}}>Bodhi ID: {bodhi_outlet_id ? bodhi_outlet_id : ' * ' }</p> }
        </div>
      </div>
    </ChannelBox>
  );
};
